import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PostSummary from '../components/post-summary';

export default function IndexPage({ data }) {
    const posts = data.allMarkdownRemark.edges;

    return (
        <Layout>
            <SEO title="All posts" />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {posts.map(({ node }) => {
                    return (
                        <PostSummary
                            key={node.fields.slug}
                            slug={node.fields.slug}
                            title={node.frontmatter.title}
                            date={node.frontmatter.date}
                            datePretty={node.frontmatter.datePretty}
                            tags={node.frontmatter.tags}
                            readingTime={node.fields.readingTime.text}
                            description={node.frontmatter.description}
                        />
                    );
                })}
            </div>
        </Layout>
    );
}

export const query = graphql`
    query {
        allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
            edges {
                node {
                    fields {
                        slug
                        readingTime {
                            text
                        }
                    }
                    frontmatter {
                        title
                        date(formatString: "YYYY-MM-DD")
                        datePretty: date(formatString: "MMMM Do, YYYY")
                        tags
                        description
                    }
                }
            }
        }
    }
`;
